<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-10">
        <h1>Scra/MO/ble</h1>
        <h3>{{ $t('Connection') }}</h3>
        <alert :message=message v-if="showMessage"></alert>
        <hr><br><br>
        <b-form @submit="onSubmit" class="w-100">
        <b-form-group id="form-title-group"
                      :label="$t('Auth')+':'"
                      label-for="form-title-input">
            <b-form-input id="form-title-input"
                          type="text"
                          v-model="username"
                          required
                          :placeholder="$t('namePlaceholder')">
            </b-form-input>
            <b-form-input id="form-pwd-input"
                          type="password"
                          v-model="pwd"
                          :placeholder="$t('pwdPlaceholder')">
                          required>
            </b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">{{ $t('Connect') }}</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Alert from './Alert.vue';

export default {
  data() {
    return {
      username: '',
      token: '',
      pwd: '',
      message: '',
      showMessage: false,
      rootPath: 'https://saf.staging.aws.afola.ovh/api',
    };
  },
  components: {
    alert: Alert,
  },
  methods: {
    reachMenu() {
      this.$router.push({
        name: 'Menu',
        params: {
          user: this.username,
          token: this.token,
        },
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      /* Check if exists */
      const path = `${this.rootPath}/users/`;
      axios.get(path)
        .then((res) => {
          if (!res.data.users.includes(this.username)) {
            const pathN = `${this.rootPath}/user/new/`;
            axios.post(pathN, { name: this.username, pwd: this.pwd })
              .then((resN) => {
                if (resN.data.status) {
                  this.message = 'User created!';
                  this.showMessage = true;
                  this.token = resN.data.token;
                  this.reachMenu();
                } else {
                  this.message = this.$t('usernotcreated');
                  this.showMessage = true;
                }
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
                this.getGames();
              });
          } else { // exists
            const pathCo = `${this.rootPath}/user/connect/`;
            axios.post(pathCo, { name: this.username, pwd: this.pwd })
              .then((resN) => {
                if (resN.data.token) {
                  // alert(resN.data.token);
                  this.token = resN.data.token;
                  this.reachMenu();
                } else {
                  alert('Auth failed');
                }
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.log(error);
              });
            // this.reachMenu();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Connection": "Connection",
    "Connect": "Connect",
    "Auth": "Authentication",
    "pwdPlaceholder": "Enter your password",
    "namePlaceholder": "Enter your name",
    "usernotcreated": "User not created"
  },
  "fr": {
    "Connection": "Connexion",
    "Connect": "Connexion",
    "Auth": "Authentification",
    "pwdPlaceholder": "Entrez votre mot de passe",
    "namePlaceholder": "Entrez votre nom d'utilisateur",
    "usernotcreated": "Utilisateur non créé"
  }
}
</i18n>
