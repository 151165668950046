<template>
<div class="flex-group" style="">
  <h3>{{ title }}</h3>
  <p>{{ txt }}</p>
</div>
</template>

<script>
export default {
  props: ['title', 'txt'],
};
</script>
