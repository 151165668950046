<template>
  <span>
    <template v-if="status == 'Finished'">{{ $t('Gamefinished') }}</template>
    <template v-else-if="wanted == player">{{ $t('Yourturn') }}</template>
    <template v-else>{{ $t('Turnof') }} {{ wanted }}</template>
  </span>
</template>

<script>
export default {
  props: ['player', 'wanted', 'status'],
};
</script>

<i18n>
{
  "en": {
    "Gamefinished": "Game finished",
    "Yourturn": "Your turn",
    "Turnof": "Turn of"
  },
  "fr": {
    "Gamefinished": "Partie terminée",
    "Yourturn": "Votre tour",
    "Turnof": "Tour de"
  }
}
</i18n>
