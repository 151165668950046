<template>
<button type="button" :class="btnlevel"
@click="playLetters">{{ playorpass }}</button>
</template>

<script>
export default {
  props: ['btnlevel', 'playorpass'],
  methods: {
    playLetters() {
      this.$parent.playLetters();
    },
  },
};
</script>
