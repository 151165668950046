<style>
.letter {
  display:inline-block;
  background-color: #DDDD88;
  border: 1px solid yellow;

  width:40px;
  height:40px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.letter-selected {
  border: 1px solid green;
}
.letter-moved {
  border: 1px solid gray;
  background-color: gray;
}
</style>

<template>
<div>
  <div class="letter" style="margin:5px;" v-for="letterPos in letters.length"
          :key="letterPos">
    <div v-on:click="selectLetter(letterPos-1)">
      <template v-if="letterPos-1 == selected">
      <div class="letter-selected">
          <span style="font-size: x-large;">
            {{ letters[letterPos-1] }}
          </span>
          <span style="font-size:small;">
            <template v-if="letters[letterPos-1] in points">
              <template v-if="points[letters[letterPos-1]] > 0">
                {{ points[letters[letterPos-1]] }}
              </template>
            </template>
            <template v-else>
              1
            </template>
          </span>
          </div>
      </template>
      <template v-else-if="letterPos-1 in moved">
      <div class="letter-moved">
          <span style="font-size: x-large;">
            {{ letters[letterPos-1] }}
          </span>
          <span style="font-size:small;">
            <template v-if="letters[letterPos-1] in points">
              <template v-if="points[letters[letterPos-1]] > 0">
                {{ points[letters[letterPos-1]] }}
              </template>
            </template>
            <template v-else>
              1
            </template>
          </span>
          </div>
      </template>
      <template v-else>
          <span style="font-size: x-large;">
            {{ letters[letterPos-1] }}
          </span>
          <span style="font-size:small;">
            <template v-if="letters[letterPos-1] in points">
              <template v-if="points[letters[letterPos-1]] > 0">
                {{ points[letters[letterPos-1]] }}
              </template>
            </template>
            <template v-else>
              1
            </template>
          </span>
      </template>
      </div>
      </div>
  </div>
</template>

<script>
export default {
  props: ['letters', 'points', 'selected', 'moved'],
  methods: {
    selectLetter(l) {
      this.$parent.selectLetter(l);
    },
  },
};
</script>
