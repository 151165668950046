<template>
  <div>
    <p v-for="p, v in scores" :key="p">
        <template v-if="v == player">{{ $t('You') }}</template>
        <template v-else>{{ v }}</template>
        : {{ p }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['scores', 'player'],
};
</script>

<i18n>
{
  "en": {
    "You": "You"
  },
  "fr": {
    "You": "Vous"
  }
}
</i18n>
