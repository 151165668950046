<style>
.scra-table {
  border: 3px solid black;
}
.scra-cell {
  text-align: center;
  border: 2px solid white;
  width:50px;
  height:50px;
  cursor: pointer;
  background-color: #CCCCCC;
}
.case-wordD {
  background-color: pink;
}
.case-wordT {
  background-color: red;
}
.case-letterD {
  background-color: #AAAAFF;
}
.case-letterT {
  background-color: blue;
}
.letter {
  display:inline-block;
  background-color: #DDDD88;
  border: 1px solid yellow;

  width:40px;
  height:40px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.text-bg {
  color: black;
  font-size: x-small;
}
td a {
    display:block;
    width:100%;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
}
.flex-container > div {
  background-color: #f1f1f1;
  margin: 10px;
  padding: 10px;
}
</style>

<template>
  <div class="container">
  <div class="flex-group">
    <p>{{ $t('connected') }} <strong>{{ player }} </strong>
       <small><a href="#" v-on:click="backToConnectPage">({{ $t('disconnect') }})</a></small></p>

    <h1>{{ $t('Game') }} {{ gid }}</h1>
  <p><small><a href="#" v-on:click="backToMenu">{{ $t('backtogames') }}</a></small></p>
        <hr><br>
    </div>

  <div class="flex-container">
  <div class="flex-group" style="">
    <h3>{{ $t('Playing') }}</h3>
  <turntxt :player=player :status=gameStatus :wanted=wantedPlayer></turntxt>
  </div>
  <div class="flex-group" style="">
    <h3>{{ $t('Scores') }}</h3>
    <score :scores=scoreData :player=player></score>
  </div>

  <quicktxt :title="$t('Pouch')" :txt="sachetLevel"></quicktxt>

  <div class="flex-group">
<table class="scra-table">
  <tbody>
    <tr v-for="indexY in boardData.dims[1]" :key="indexY">
      <td class="scra-cell"  :class="['case-'+boardData.cases[''+(indexX-1)+','+(indexY-1)]]"
      v-for="indexX in boardData.dims[0]" :key="indexX">
          <div href="#" v-on:click="selectCell(''+(indexX-1)+','+(indexY-1))"
          :id="'cell-' + (indexX-1) + '-' + (indexY-1)">
          <template v-if="''+(indexX-1)+','+(indexY-1) in boardData.letters">
            <div class="letter">
              <span style="font-size: x-large;">
                {{ boardData.letters[''+(indexX-1)+','+(indexY-1)].slice(-1) }}
              </span>
              <span style="font-size:small;">
       <template v-if="boardData.letters[''+(indexX-1)+','+(indexY-1)].slice(0,1) in letterPoints">
   <template v-if="letterPoints[boardData.letters[''+(indexX-1)+','+(indexY-1)].slice(0,1)] !== 0">
                    {{ letterPoints[boardData.letters[''+(indexX-1)+','+(indexY-1)].slice(0,1)] }}
                        </template>
                </template>
                <template v-else>
                  1
                </template>
              </span>
            </div>
          </template>
          <template v-else-if="''+(indexX-1)+','+(indexY-1) in boardData.cases">
            <span class="text-bg">
                {{ $t(boardData.cases[''+(indexX-1)+','+(indexY-1)]) }}
            </span>
          </template>
          <template v-else>
            <tiny>&nbsp;</tiny>
          </template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <br>
  </div>

  <alert :type-m=type-m :message=message v-if="showMessage"></alert>
  <template v-if="gameStatus != 'Finished'">
    <div class="row">
  <div class="col-lg">
    <h3>{{ $t('Yourletters') }}
      <div class="btn-group" role="group">
          <playbutton :btnlevel=buttonLevel :playorpass="$t(playOrPass)"></playbutton>
          <button type="button" class="btn btn-secondary btn-sm"
@click="cleanLetters">{{ $t('Clean') }}</button>
          <button type="button" class="btn btn-warning btn-sm" v-b-modal.change-letters-modal>
            {{ $t('Changeletters') }}
          </button>
              </div></h3>
    <!-- MOVE AVAILABLE LETTERS DISPLAY IN FUNCTION (and later as component) -->
        <lettersbar :letters=availableLetters
                    :points=letterPoints
                    :selected=selectedLetterRealPos
                    :moved=movingLetters></lettersbar>
  </div>
  </div>
</template>

  </div>

  <b-modal ref="jokerLetterModal"
         id="joker-modal"
         :title="$t('JokerLetter')"
         hide-footer>
  <b-form @submit="onSubmit" @reset="onReset" class="w-100">
  <b-form-group id="form-title-group"
                :label="$t('Letter')"
                label-for="form-title-input">
      <b-form-input id="form-title-input"
                    type="text"
                    v-model="jokerForm.letter"
                    required
                    :placeholder="$t('Letterofreplacement')">
      </b-form-input>
    </b-form-group>
    <b-button type="submit" variant="primary">{{ $t('Create') }}</b-button>
    <b-button type="reset" variant="danger">{{ $t('Cancel') }}</b-button>
  </b-form>
</b-modal>

  <b-modal ref="changeLettersModal"
         id="change-letters-modal"
         :title="$t('Changingletters')+' ('+changeWarning+')'"
         hide-footer>
  <b-form @submit="onSubmitLetters" @reset="onReset" class="w-100">
  <b-form-group id="form-title-group"
              :label="$t('yourletters')+availableLetters+$t('tochange')"
                label-for="form-title-input">
      <b-form-input id="form-title-input"
                    type="text"
                    v-model="lettersForm.letters"
                    required
                    :placeholder="$t('changePlaceholder')">
      </b-form-input>
    </b-form-group>
    <b-button type="submit" variant="primary">{{ $t('Change') }}</b-button>
    <b-button type="reset" variant="danger">{{ $t('Cancel') }}</b-button>
  </b-form>
</b-modal>

  </div>
</template>

<script>
import axios from 'axios';
import Alert from './Alert.vue';
import Score from './Score.vue';
import PlayButton from './PlayButton.vue';
import TurnTxt from './TurnTxt.vue';
import LettersBar from './LettersBar.vue';
import QuickTxt from './QuickTxt.vue';

export default {
  data() {
    return {
      gameStatus: '',
      token: this.$route.params.token,
      message: '',
      typeM: '',
      changeWarning: this.$t('skipturn'),
      showMessage: false,
      player: this.$route.params.player,
      boardData: {},
      scoreData: {},
      sachetLevel: '',
      dimX: 0,
      dimY: 0,
      gid: this.$route.params.gidp,
      letterPoints: {},
      availableLetters: [],
      movingLetters: {},
      stableLetters: {},
      previousPosContent: {},
      wantedPlayer: '',
      rootPath: 'https://saf.staging.aws.afola.ovh/api',

      selectedLetter: -1,
      selectedLetterRealPos: -1,
      selectedPos: '',
      posPreviousColor: '',
      jokerForm: {
        letter: '',
      },
      lettersForm: {
        letters: '',
      },

      playOrPass: 'Pass',
      buttonLevel: 'btn btn-danger btn-sm',
    };
  },
  components: {
    alert: Alert,
    score: Score,
    lettersbar: LettersBar,
    turntxt: TurnTxt,
    playbutton: PlayButton,
    quicktxt: QuickTxt,
  },
  methods: {
    cellIdFromPos(pos) {
      const p = pos.split(',');
      return `cell-${p[0]}-${p[1]}`;
    },
    backToConnectPage() {
      this.$router.push({
        name: 'Connect',
      });
    },
    backToMenu() {
      this.$router.push({
        name: 'Menu',
        params: {
          user: this.player,
          token: this.token,
        },
      });
    },
    getPointsTxt(l) {
      if (l in this.letterPoints) {
        const p = this.letterPoints[l];
        if (p !== 0) {
          return p;
        }
        return '';
      }
      return 1;
    },
    placeLetter() {
      if (this.selectedLetter === '_') {
        this.$refs.jokerLetterModal.show();
        return;
      }
      const mlslrp = this.movingLetters[this.selectedLetterRealPos];
      if (mlslrp !== undefined) {
        this.cleanPos(mlslrp.pos);
      }
      const element = document.getElementById(this.cellIdFromPos(this.selectedPos));
      this.previousPosContent[this.selectedPos] = element.innerHTML;
      element.innerHTML = `
      <div class="letter">
        <span style="font-size: x-large;">
          ${this.selectedLetter.slice(-1)}
        </span>
        <span style="font-size:small;">
          ${this.getPointsTxt(this.selectedLetter.slice(0, 1))}
        </span>
      </div>`;

      this.movingLetters[this.selectedLetterRealPos] = {
        pos: this.selectedPos,
        value: this.selectedLetter,
      };

      this.playOrPass = 'Play';
      this.buttonLevel = 'btn btn-primary btn-sm';

      this.resetPosBG();
      this.selectedPos = '';
      this.selectedLetter = -1;
      this.selectedLetterRealPos = -1;
    },
    selectLetter(lp) {
      this.selectedLetterRealPos = lp;
      this.selectedLetter = this.availableLetters[lp];
      // TODO SUBROUTINE FOR JOKER
      if (this.selectedPos !== '') {
        this.placeLetter();
      }
    },
    resetPosBG() {
      if (this.selectedPos !== '') {
        const element = document.getElementById(this.cellIdFromPos(this.selectedPos));
        element.style.backgroundColor = this.posPreviousColor;
      }
    },
    selectCell(pos) {
      this.resetPosBG();

      this.selectedPos = pos;
      const element = document.getElementById(this.cellIdFromPos(this.selectedPos));
      this.posPreviousColor = element.style.backgroundColor;
      element.style.backgroundColor = '#117733';
      if (this.selectedLetter !== -1) {
        // Real placement
        this.placeLetter();
      }
    },
    getWanted() {
      const pathS = `${this.rootPath}/game/${this.gid.toString()}/wanted`;
      axios.get(pathS, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          this.wantedPlayer = res.data.player;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getStatus() {
      const pathS = `${this.rootPath}/game/${this.gid.toString()}/status`;
      axios.get(pathS, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          this.gameStatus = res.data.status;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getScore() {
      const pathS = `${this.rootPath}/game/${this.gid.toString()}/scores`;
      axios.get(pathS, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          this.scoreData = res.data.scores;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getLetters() {
      const pathLe = `${this.rootPath}/game/${this.gid.toString()}/letters/${this.player}`;
      axios.get(pathLe, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          this.availableLetters = res.data.letters;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getSachetLevel() {
      const pathLe = `${this.rootPath}/game/${this.gid.toString()}/sachet/level`;
      axios.get(pathLe, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          this.sachetLevel = this.$t(res.data.level);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getData() {
      const path = `${this.rootPath}/game/${this.gid.toString()}/board`;
      axios.get(path, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          this.boardData = res.data.board;
          /* [this.dimX] = this.boardData.dims;
          [this.dimY] = this.boardData.dims; */
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      this.getScore();
      const pathL = `${this.rootPath}/game/${this.gid.toString()}/letter-points`;
      axios.get(pathL, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          this.letterPoints = res.data.points;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
      this.getLetters();
      this.getWanted();
      this.getStatus();
      this.getSachetLevel();
    },
    cleanMessage() {
      this.message = '';
      this.showMessage = false;
    },
    cleanPos(pos) {
      const element = document.getElementById(this.cellIdFromPos(pos));
      element.innerHTML = this.previousPosContent[pos];
    },
    cleanLetters() {
      Object.values(this.movingLetters).forEach((ml) => {
        this.cleanPos(ml.pos);
      });
      this.movingLetters = {};
      this.playOrPass = 'Pass';
      this.buttonLevel = 'btn btn-danger btn-sm';
    },
    playLetters() {
      this.cleanMessage();
      // convert letters into API format
      console.log(this.movingLetters);
      const formattedLetters = {};
      Object.values(this.movingLetters).forEach((ml) => {
        formattedLetters[ml.pos] = ml.value;
      });
      const pathS = `${this.rootPath}/game/${this.gid.toString()}/play`;
      axios.post(pathS, { player: this.player, letters: formattedLetters }, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          if (res.data.status) {
            this.message = 'Move played';
            if (res.data.messages !== []) {
              Object.values(res.data.messages).forEach((m) => {
                this.message += ` (${m})`;
              });
            }
            this.typeM = 'success';
            this.showMessage = true;
            Object.keys(this.movingLetters).forEach((l) => {
              this.stableLetters[l] = this.movingLetters[l];
            });
            this.movingLetters = {};

            // refresh score and turn info
            this.getScore();
            this.getSachetLevel();
            this.getLetters();
            this.getWanted();
            this.getStatus();
          } else {
            this.message = 'Move not authorized';
            this.typeM = 'warning';
            if (res.data.messages !== []) {
              Object.values(res.data.messages).forEach((m) => {
                this.message += ` (${m})`;
              });
            }
            this.showMessage = true;
            // reset letters
            this.cleanLetters();
          }
          // this.scoreData = res.data.scores;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$refs.jokerLetterModal.hide();
      this.selectedLetter += this.jokerForm.letter;
      this.placeLetter();
    },
    onSubmitLetters(evt) {
      evt.preventDefault();
      this.cleanMessage();
      const pathS = `${this.rootPath}/game/${this.gid.toString()}/letters/${this.player}/change`;
      if (this.lettersForm.letters !== '') {
        axios.post(pathS, { letters: this.lettersForm.letters }, { headers: { Authorization: `${this.token}` } })
          .then((res) => {
            if (res.data.status) {
              this.$refs.changeLettersModal.hide();
              this.getLetters();
              this.getWanted();
              this.lettersForm.letters = '';
              this.message = 'Letters changed and turn passed.';
              this.showMessage = true;
            } else {
              let sup = '';
              if (res.data.messages) {
                Object.values(res.data.messages).forEach((m) => {
                  sup += ` (${m})`;
                });
              }
              alert(`Failed${sup}`);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      // TODO add answer management
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.jokerLetterModal.hide();
      this.$refs.changeLettersModal.hide();
    },
  },
  created() {
    console.log('gid', this.gid);
    this.getData();
  },
};
</script>

<i18n>
{
  "en": {
    "connected": "Connected as",
    "disconnect": "Disconnect",
    "Game": "Game",
    "backtogames": "Back to games",
    "Playing": "Playing",
    "Scores": "Scores",
    "Pouch": "Pouch",
    "Full":"Full",
    "Almostfull": "Almost full",
    "Halffull": "Half full",
    "Fewletters": "Few letters",
    "Empty": "Empty",
    "wordD": "Word double",
    "wordT": "Word triple",
    "letterD": "Letter double",
    "letterT": "Letter triple",
    "Pass": "Pass",
    "Play": "Play",
    "Changeletters": "Change letters",
    "Clean": "Clean",
    "Yourletters": "Your letters",
    "JokerLetter":"Letter for the joker",
    "Letter": "Letter:",
    "Letterofreplacement": "Letter of replacement",
    "Create": "Create",
    "Change": "Change",
    "Cancel": "Cancel",
    "changePlaceholder": "Letters to redraw, for example 'J' or 'APOI' or 'A_'",
    "yourletters": "Your letters are: ",
    "tochange": ". Letter(s) to change:",
    "Changingletters": "Changing letters",
    "skipturn": "will skip your turn"
  },
  "fr": {
    "connected": "Connecté en tant que",
    "disconnect": "Déconnexion",
    "Game": "Partie",
    "backtogames": "Retour aux parties",
    "Playing": "Tour",
    "Scores": "Scores",
    "Pouch": "Sachet",
    "Full":"Full",
    "Almostfull": "Quasi-plein",
    "Halffull": "Mi-plein",
    "Fewletters": "Peu de lettres",
    "Empty": "Vide",
    "wordD": "Mot double",
    "wordT": "Mot triple",
    "letterD": "Lettre double",
    "letterT": "Lettre triple",
    "Pass": "Passer",
    "Play": "Jouer",
    "Changeletters": "Changer des lettres",
    "Clean": "Nettoyer",
    "Yourletters": "Vos lettres",
    "JokerLetter":"Lettre pour le joker",
    "Letter": "Lettre :",
    "Letterofreplacement": "Lettre de remplacement",
    "Create": "Créer",
    "Change": "Changer",
    "Cancel": "Annuler",
    "changePlaceholder": "Lettres à retirer, par exemple 'J' ou 'APOI' ou 'A_'",
    "yourletters": "Vos lettres sont: ",
    "tochange": ". Lettre(s) à changer :",
    "Changingletters": "Changer de lettres",
    "skipturn": "terminera votre tour"
  }
}
</i18n>
