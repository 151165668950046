<template>
  <div>
    <b-alert variant="primary" show>{{ message }}</b-alert>
  </div>
</template>

<script>
export default {
  props: ['message'],
};
</script>
