import Vue from 'vue';
import Router from 'vue-router';
import Game from '../components/Game.vue';
import Menu from '../components/Menu.vue';
import Connect from '../components/Connect.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Connect',
      component: Connect,
    },
    {
      path: '/menu',
      name: 'Menu',
      component: Menu,
    },
    {
      path: '/game',
      name: 'Game',
      component: Game,
    },
  ],
});
