<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-10">
        <h1>Scra/MO/ble</h1>
        <h2>{{ $t('games') }}</h2>
        <hr><br><br>
        <p>{{ $t('connected') }} <strong>{{ user }} </strong>
         <small><a href="#" v-on:click="backToConnectPage"> ({{ $t('disconnect') }})</a></small></p>
        <alert :message=message v-if="showMessage"></alert>
        <button type="button" class="btn btn-success btn-sm" v-b-modal.game-modal>
          {{ $t('newgame') }}
        </button>
        <br><br>
        <table class="table table-hover">
          <thead>
            <tr>
              <!--th scope="col">{{ $t('since') }}</th-->
              <th scope="col">{{ $t('with') }}</th>
              <th scope="col">{{ $t('turn') }}</th>
              <th scope="col">{{ $t('status') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(game, index) in games" :key="index">
              <!--td>{{ game.since }}</td-->
              <td>{{ displayOpponents(game.players) }}</td>
              <td>{{ game.turn }}</td>
              <td>{{ $t(game.status) }}</td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm"
        @click="onPlayGame(game.gid)">{{ $t('play') }}</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal ref="newGameModal"
         id="game-modal"
         title="Create a game"
         hide-footer>
  <b-form @submit="onSubmit" @reset="onReset" class="w-100">
  <b-form-group id="form-title-group"
                label="Players:"
                label-for="form-title-input">
      <b-form-input id="form-title-input"
                    type="text"
                    v-model="newGameForm.players"
                    required
                    placeholder="Enter player names separated with ';'">
      </b-form-input>
    </b-form-group>
    <b-button type="submit" variant="primary">Create</b-button>
    <b-button type="reset" variant="danger">Cancel</b-button>
  </b-form>
</b-modal>

  </div>
</template>

<script>
import axios from 'axios';
import Alert from './Alert.vue';

export default {
  data() {
    return {
      games: [],
      rootPath: 'https://saf.staging.aws.afola.ovh/api',
      message: '',
      user: this.$route.params.user,
      token: this.$route.params.token,
      showMessage: false,
      newGameForm: {
        players: '',
      },
    };
  },
  components: {
    alert: Alert,
  },
  methods: {
    backToConnectPage() {
      this.$router.push({
        name: 'Connect',
      });
    },
    displayOpponents(players) {
      let opp = '';
      Object.values(players).forEach((p) => {
        if (p !== this.user) {
          opp += `${p}, `;
        }
      });
      return opp.substring(0, opp.length - 2);
    },
    filterGames(games) {
      const fg = [];
      Object.values(games).forEach((g) => {
        if (g.players.includes(this.user)) {
          fg.push(g);
        }
      });
      return fg;
    },
    getGames() {
      const path = `${this.rootPath}/games/`;
      axios.get(path, { headers: { Authorization: `${this.token}` } })
        .then((res) => {
          this.games = this.filterGames(res.data.games);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    reachGame(gidi) {
      console.log(gidi);
      /* TODO how to inject args:
      1) directly for game number
      2) undirectly for user name and env vars */
      this.$router.push({
        name: 'Game',
        params: {
          gidp: gidi,
          player: this.user,
          token: this.token,
        },
      });
    },
    createGame(payload) {
      const path = `${this.rootPath}/game/new/`;
      axios.post(path, payload, { headers: { Authorization: `${this.token}` } }) // Bearer
        .then((res) => {
          this.message = 'Game created!';
          this.showMessage = true;
          this.getGames();
          /* TODO go to game page */
          this.reachGame(res.data.gid);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.getGames();
        });
    },
    initForm() {
      this.newGameForm.players = `${this.user};`;
    },
    onPlayGame(gid) {
      this.reachGame(gid);
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$refs.newGameModal.hide();
      const payload = {
        players: this.newGameForm.players,
        token: this.token,
      };
      this.createGame(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.newGameModal.hide();
      this.initForm();
    },
  },
  created() {
    this.getGames();
    this.initForm();
  },
};
</script>

<i18n>
{
  "en": {
    "games": "Games",
    "connected": "Connected as",
    "disconnect": "Disconnect",
    "newgame": "New game",
    "with": "With",
    "since": "Since",
    "turn": "Turn",
    "status": "Status",
    "play": "Play",
    "Ongoing": "Ongoing",
    "Begin": "Begin",
    "Ongoing": "Ongoing",
    "Finished": "Finished"
  },
  "fr": {
    "games": "Parties",
    "connected": "Connecté en tant que",
    "disconnect": "Déconnexion",
    "newgame": "Nouvelle partie",
    "with": "Avec",
    "since": "Depuis",
    "turn": "Tour",
    "status": "État",
    "play": "Jouer",
    "Begin": "Début",
    "Ongoing": "En cours",
    "Finished": "Terminée"
  }
}
</i18n>
